<template>
<div>
  <b-col md='12' class="mt-1">
    <custom-input
      v-if="variables"
      :id="`custom-input-r1`"
      :possibleValues="variables"
      v-model="agent.register_1"
      advanced
    >
      <template #label>
        {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
        <small class="float-right text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</small>
      </template>
    </custom-input>
    <div v-else class="mb-2">
      <b-skeleton class="mb-25" width="25%" height="12px" />
      <b-skeleton class="mb-25" width="100%" height="37px" />
      <b-skeleton class="mb-25" width="25%" height="14px" />
    </div>
  </b-col>

  <!-- Comparison Icon: =========== -->
  <b-row class="justify-content-center d-flex ">
    <slot name=icon ><p class="text-danger text-center">--error: required slot undefined--</p></slot>
  </b-row>
  <!-- ============================ -->

    
    <b-col md='12' class="">
      <custom-input
        v-if="variables"
        class="my-1"
        :id="`custom-input-r2`"
        :possibleValues="variables"
        v-model="agent.register_2"
        advanced
      >
        <template #label>
          {{ $t(`agent.list.${i18nKey}.register_2.title`) }}
          <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
          <small class="float-right text-secondary">{{ $t(`agent.list.${i18nKey}.register_2.description`) }}</small>
        </template>
      </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px" />
        <b-skeleton class="mb-25" width="100%" height="37px" />
        <b-skeleton class="mb-25" width="25%" height="14px" />
      </div>
    </b-col>
  

  <b-collapse :visible="!full">
    <!-- version without destiny -->
    <!-- ========= TRUE / FALSE CARDS ========== -->
    <b-row>
      <b-col class="d-flex justify-content-center mt-0 mb-2" md="12">
        <b-col md="6" class="d-flex justify-content-end">
          <div class="w-100">
            <div class="text-right">
              {{ $t(`common.terms.if` ).toUpperCase() }}<b> {{ $t(`common.terms.false`).toUpperCase() }}</b>
            </div>
            <div class="p-1 w-100 if-card-false text-left">
              <!-- custom css class (see 'styles' below) -->
              <b-icon icon="exclamation-triangle" scale="1.7" class="mx-1"></b-icon>
              {{ $t(`common.terms.card_error`) }}
            </div>
          </div>
          <div>
            <b-icon icon="arrow-return-left" scale="2" class="mx-1 mt-3"></b-icon>
          </div>
        </b-col>
        <!-- middle -->
        <b-col md="6" class="d-flex justify-content-end">
          <div>
            <b-icon icon="arrow-return-right" scale="2" class="mx-1 mt-3"></b-icon>
          </div>
          <div class="w-100">
            <div class="text-left">
              {{ $t(`common.terms.if` ).toUpperCase() }}<b> {{ $t(`common.terms.true`).toUpperCase() }}</b>
            </div>
            <div class="p-1 w-100 if-card-true text-left">
              <!-- custom css class (see 'styles' below) -->
              <b-icon icon="play" scale="2" class="mx-1"></b-icon>
              {{ $t(`common.terms.card_continue`) }}
            </div>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </b-collapse>
  <!-- ========================================== -->

  <b-collapse :visible="full">
    <!-- version with destiny: -->
      <b-col md="12" class="d-flex justify-content-center">
        <span class="text-center"><b>{{ $t(`common.terms.store_result`) }} </b></span>
      </b-col>
      <b-col md="12" class="d-flex justify-content-center mt-1">
        <b-icon icon="arrow-down-short" scale="2" class="mx-1" variant="success"></b-icon>
      </b-col>

      <b-col md='12'>
        <custom-input
          v-if="variables"
          class="my-1"
          id="custom-input-rd"
          :possibleValues="variables"
          v-model="agent.register_destiny"
          advanced
        >
          <template #label>
            {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
            <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
            <small class="float-right text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</small>
          </template>
        </custom-input>
      </b-col>
  </b-collapse>

  <b-row>
    <b-col class="d-flex justify-content-end">
      <b-button variant="outline-purple" @click="full=!full" class="mx-2 danger">
        <span v-if="full">{{ $t(`common.terms.remove_destiny`) }}</span>
        <span v-else>{{ $t(`common.terms.add_destiny`) }}</span>
      </b-button>
    </b-col>
  </b-row>
</div>

</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BFormCheckbox,
  BSidebar,
  BLink,
  BButton,
  BCollapse,
  BModal,
  BSkeleton
} from 'bootstrap-vue'

import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperOptions from "@/custom/class/HelperOptions.js";
import SourceValue from "@/custom/class/SourceValue.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from "vuex";
import Sources from '@/custom/class/Enum/Sources.js'
import Source from '@/custom/class/Agent/Source.js';
import VSelect from 'vue-select'
import HelperTooltip from '@/layouts/components/HelperTooltip'
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";

  import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    components: {
      BContainer,
      BCol,
      BRow,
      BFormCheckbox,
      BSidebar,
      VariablesPanel,
      CustomInput,
      HelperOptions,
      SourceValue,
      BLink,
      VuePerfectScrollbar,
      BButton,
      BCollapse,
      BModal,
      VSelect,
      BSkeleton,
      HelperTooltip
    },
    props: {
      actionID: {
        type: [ Number, String ],
        required: true
      },
      uuid: {
        type: String,
        required: true
      },
      middlewareID: {
        type: Number,
        required: true
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      width: {
        type: String,
        default: '40%'
      },
      i18nKey: {
        type: String,
        required: true
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables:{
        type: Object,
        default: undefined
      }
    },
    data() {
      return {
        defaultAgent: {
          id: null,
          enum_agent_action_id: this.actionID,
          agent_block_id: this.agentBlockID,
          register_1: {
            source: 7,
            value: ""
          },
          register_2: {
            source: 7,
            value: "",
          },
          register_destiny: {
            source: 10,
            value: ""
          },
          execution_order: 1,
          fatal_on_fail: true,
        },

        uuidMap: {},
        full: false,

        variant_map: null,
        isSaving: false,
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      agent_block_id() {
        return this.agentBlockId || this.agentBlock
      },
      sourceOptions() {
        return new Sources().items
      },
      toSaveRegs() {
        if(this.full) {
          return {
            register_1: true,
            register_2: true,
            register_destiny: true,
          }
        } else {
          return {
            register_1: true,
            register_2: true,
            register_destiny: false,
          }
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
      init() {
        DefaultAgent.fetchAgentByID(this)
        this.$nextTick(() => { 
          this.full = this.checkOptionalRegisterValue()
        })
      },
      save() {
        return DefaultAgent.save(this)
      },
      validate() {
        return DefaultAgent.validate(this)
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      checkOptionalRegisterValue() {
        const registers = ['register_1', 'register_2', 'register_destiny']
        let fullState = false

        registers.forEach((register) => {
          if (this.agent?.[register].source) {
            fullState = true
          }
        });
        return fullState
      },
    }
  }
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


.agent-sidebar {
  .b-sidebar {
    min-width: 300px !important;
    max-width: 700px !important;
  }
}
.if-card-true{
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid #28c76f;
}
.if-card-false{
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid #ea5455;
}

</style>